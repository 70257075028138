import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Header } from './Header'
import { FileList } from './FileList'
import { useFileUpload } from '../useFileUpload'

const FileUploader = ({ label = 'choose file(s)', multiple = true }) => {
  const { addFiles, files } = useFileUpload()
  const onFilesAdded = (files) => {
    const newFilesData = [...files].map((file) => {
      return {
        id: uuidv4(),
        name: file.name,
        size: file.size,
        type: file.type,
        blobUrl: URL.createObjectURL(file), // Remember to call URL.revokeObjectURL to prevent memory leak
      }
    })

    addFiles(newFilesData)
  }

  return (
    <div>
      <div className="dome-file-uploader">
        <Header onFilesAdded={onFilesAdded} multiple={multiple}>
          <div className="dome-d-flex dome-align-center dome-justify-center dome-gap6">
            <i className="fal fa-upload"></i>
            <span className="dome-p2 dome-color-med-grey">{label}</span>
          </div>
        </Header>
        <FileList files={files} />
      </div>
    </div>
  )
}

export default FileUploader
