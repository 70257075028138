import { useState, useCallback, useEffect } from 'react'

export function useUploadQueue(concurrency = 5, upload) {
  const [queue, setQueue] = useState([])
  const [failedItems, setFailedItems] = useState([])
  const [activeUploads, setActiveUploads] = useState(0)

  const addToQueue = useCallback((items) => {
    setQueue((prev) => [...prev, ...items])
  }, [])

  useEffect(() => {
    if (activeUploads >= concurrency || queue.length === 0) return

    const itemsToProcess = queue.slice(0, concurrency - activeUploads)
    setActiveUploads((prev) => prev + itemsToProcess.length)
    setQueue((prev) => prev.slice(itemsToProcess.length))

    itemsToProcess.forEach(async (item) => {
      try {
        await upload(item.uuid) // Call the provided upload function
        URL.revokeObjectURL(item.blobUrl)
      } catch (_e) {
        setFailedItems((prev) => [...prev, item])
      } finally {
        setActiveUploads((prev) => prev - 1)
      }
    })
  }, [queue, activeUploads, concurrency, upload])

  return { addToQueue, queue, activeUploads, failedItems }
}
