import { useState, useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'
import { useUploadContext } from './uploadContext'
import { uploadApi } from './api'
import { UPLOAD_STAGES, POLLING_CONFIG } from './constants'
import { useUploadQueue } from './useUploadQueue'

async function toFile(blobUrl, name, type) {
  const blob = await fetch(blobUrl).then((r) => r.blob())
  return new File([blob], name, { type: type })
}

async function calculateMD5(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const buffer = e.target.result
      const wordArray = CryptoJS.lib.WordArray.create(buffer)
      const md5 = CryptoJS.MD5(wordArray)
      resolve(CryptoJS.enc.Base64.stringify(md5))
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

async function handleFilesData(filesData) {
  const newFiles = {}
  const itemsToProcess = []
  for (const fileData of filesData) {
    try {
      const uuid = uuidv4()
      newFiles[uuid] = await toFile(
        fileData.blobUrl,
        fileData.name,
        fileData.type
      )
      itemsToProcess.push({ uuid, blobUrl: fileData.blobUrl })
      // URL.revokeObjectURL(fileData.blobUrl)
    } catch (e) {}
  }

  return { newFiles, itemsToProcess }
}

export const useFileUpload = () => {
  const { dispatch } = useUploadContext()
  const pollingTimeoutRefs = useRef({})
  const pollingStartTimeRefs = useRef({})
  const [files, setFiles] = useState({})

  // const removeFile = useCallback(
  //   (uuid) => {
  //     setFiles((prevState) => {
  //       const newState = { ...prevState }
  //       delete newState[uuid]
  //
  //       return newState
  //     })
  //   },
  //   [setFiles]
  // )

  const clearPollingTimeout = useCallback((uuid) => {
    if (pollingTimeoutRefs.current[uuid]) {
      clearTimeout(pollingTimeoutRefs.current[uuid])
      delete pollingTimeoutRefs.current[uuid]
    }
  }, [])

  const startPolling = useCallback(
    (uuid) => {
      clearPollingTimeout(uuid)
      pollingStartTimeRefs.current[uuid] = Date.now()

      const pollStatus = async () => {
        try {
          const status = await uploadApi.checkStatus(uuid)

          // Only update status if we have real metadata
          if (status.processing_status !== 'pending') {
            dispatch({
              type: 'UPDATE_STATUS',
              payload: {
                uuid,
                stage:
                  status.processing_status === 'completed'
                    ? UPLOAD_STAGES.COMPLETED
                    : UPLOAD_STAGES.PROCESSING,
                metadata: status,
              },
            })
          }

          // Check if we should continue polling
          const elapsedTime = Date.now() - pollingStartTimeRefs.current[uuid]
          const maxDuration =
            status.processing_status === 'processing'
              ? POLLING_CONFIG.MAX_DURATION.PROCESSING
              : POLLING_CONFIG.MAX_DURATION.METADATA

          if (status.processing_status !== 'completed') {
            if (elapsedTime < maxDuration) {
              pollingTimeoutRefs.current[uuid] = setTimeout(
                pollStatus,
                POLLING_CONFIG.INTERVAL
              )
            } else {
              dispatch({
                type: 'SET_ERROR',
                payload: {
                  uuid,
                  code: 'TIMED_OUT',
                  message: 'Failed to process the file within the time limit',
                  details: null,
                  retryable: true,
                },
              })
              dispatch({
                type: 'UPDATE_STATUS',
                payload: {
                  uuid,
                  stage: UPLOAD_STAGES.FAILED,
                  metadata: status,
                },
              })
            }
          }
        } catch (error) {
          dispatch({ type: 'SET_ERROR', payload: { uuid, ...error } })
        }
      }

      pollStatus()
    },
    [dispatch, clearPollingTimeout]
  )

  const upload = useCallback(
    async (uuid) => {
      try {
        const file = files[uuid]
        // Calculate MD5
        const md5 = await calculateMD5(file)

        // Generate upload policy
        const { url, fields, metadata_path } = await uploadApi.generatePolicy({
          uuid,
          name: file.name,
          type: file.type,
          md5,
        })

        // TODO: Handle uuid returned from server is different from client uuid.
        // Create file entry in context
        // const fileUuid = metadata_path.split('/')[1].replace('.json', '')
        // if (uuid !== fileUuid)
        //   dispatch({
        //     type: 'UPDATE_UUID',
        //     payload: { uuid, newUuid: fileUuid },
        //   })

        dispatch({
          type: 'ADD_FILE',
          payload: {
            uuid,
            file: { name: file.name, size: file.size, type: file.type },
          },
        })

        // Upload file
        await uploadApi.uploadFile(url, fields, file, md5, (progress) => {
          dispatch({ type: 'UPDATE_PROGRESS', payload: { uuid, progress } })
        })

        // Start polling for status
        startPolling(uuid)

        return uuid
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: { uuid, ...error } })
        throw error
      }
    },
    [files, dispatch, startPolling]
  )

  const retry = useCallback(
    async (uuid) => {
      if (!uuid) return

      // Clear existing error
      dispatch({
        type: 'SET_ERROR',
        payload: { uuid, code: null, message: null, details: null },
      })

      return upload(uuid)
    },
    [upload, dispatch]
  )

  const { addToQueue } = useUploadQueue(5, upload)

  const addFiles = useCallback(
    async (filesData) => {
      const { newFiles, itemsToProcess } = await handleFilesData(filesData)
      setFiles((prevState) => ({ ...prevState, ...newFiles }))
      addToQueue(itemsToProcess)
    },
    [addToQueue]
  )

  useEffect(() => {
    return () => {
      Object.values(pollingTimeoutRefs.current).forEach((timeout) => {
        if (timeout) clearTimeout(timeout)
      })
    }
  }, [])

  return {
    files,
    addFiles,
    upload,
    retry,
  }
}
