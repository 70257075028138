import React from 'react'

export function toKb(bytes) {
  return (bytes / 1024).toFixed(0) + ' kB'
}

export function ProgressLoadingBar({ progress }) {
  return (
    <div className="dome-d-flex dome-gap6 dome-align-center">
      <div className="progress-loading-bar-wrapper">
        <div
          className="progress-loading-bar"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="dome-p3 dome-color-med-grey percentage-text">
        {progress}%
      </div>
    </div>
  )
}

export function InfiniteLoadingBar({}) {
  return (
    <div className="dome-d-flex dome-gap6 dome-align-center">
      <div className="infinite-progress-bar-wrapper">
        <div className="infinite-progress-bar"></div>
      </div>
      <div className="dome-p3 dome-color-med-grey percentage-text"></div>
    </div>
  )
}
