import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { InfiniteLoadingBar, ProgressLoadingBar, toKb } from './utils'
import { useUploadSelector } from '../selectors'
import { UPLOAD_STAGES } from '../constants'

const progressLabels = {
  uploading: 'uploading...',
  processing: 'processing...',
}

export function FileItem({ file, id }) {
  const { selectFileByUuid } = useUploadSelector()
  const fileState = selectFileByUuid(id)
  const status = useMemo(() => {
    if (!fileState.file) {
      return {
        progressLabel: progressLabels.uploading,
        uploading: true,
        progress: 0,
      }
    }

    const progress = Math.round(fileState.file.progress)
    const uploading =
      fileState.file.stage === UPLOAD_STAGES.UPLOADING && progress < 100
    const processing =
      fileState.file.stage === UPLOAD_STAGES.PROCESSING ||
      (fileState.file.stage === UPLOAD_STAGES.UPLOADING && progress === 100)
    let progressLabel = ''
    if (uploading || processing) {
      if (uploading) {
        progressLabel = progressLabels.uploading
      } else {
        progressLabel = progressLabels.processing
      }
    }

    return {
      progress: progress,
      progressLabel: progressLabel,
      uploading: uploading,
      processing: processing,
      completed: fileState.file.stage === UPLOAD_STAGES.COMPLETED,
      failed: fileState.file.stage === UPLOAD_STAGES.FAILED,
      errors:
        fileState.file.stage === UPLOAD_STAGES.FAILED
          ? fileState.error.message
          : null,
    }
  }, [fileState])

  return (
    <div className="file-item dome-d-flex dome-flex-column dome-gap6">
      <div className=" dome-d-flex dome-gap12">
        <span style={{ flexGrow: 1 }}>{file.name}</span>
        <span style={{ flexShrink: 0 }}>{toKb(file.size)}</span>
        {status.completed && ( // TODO: conditionally display preview icon
          <span style={{ flexShrink: 0 }}>
            <i className="fa-light fa-magnifying-glass"></i>
          </span>
        )}
        <span style={{ flexShrink: 0 }}>
          <i className="fa-light fa-xmark"></i>
        </span>
      </div>
      {(status.uploading || status.processing || status.failed) && (
        <div className="dome-d-flex dome-flex-column dome-gap3">
          {status.progressLabel && (
            <div className="dome-color-dark-grey progress-label">
              {status.progressLabel}
            </div>
          )}
          {status.uploading && (
            <ProgressLoadingBar progress={status.progress} />
          )}
          {status.processing && <InfiniteLoadingBar />}
          {/* TODO: Handle errors displaying */}
        </div>
      )}
    </div>
  )
}

FileItem.propTypes = {
  id: PropTypes.string.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
}
