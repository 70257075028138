import React from 'react'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import lemonadeLogo from '/app/assets/images/insurance/logos/lemonade.png'
import InstantQuoteLookupOverlayButton from '../instant_quote_lookup/overlay_button'

const Logos = () => {
  return (
    <div className="logos dome-d-flex dome-align-center dome-gap3">
      <img className="logo chubb" src={chubbLogo} alt="chubb logo" style={{ width: '50px' }} />
      <img className="logo lemonade" src={lemonadeLogo} alt="chubb logo" style={{ width: '50px' }} />
    </div>
  )
}

const Content = ({ content }) => {
  return <div className="dome-p3 dome-color-med-grey">{content}</div>
}

const ClickableHeader = ({ header }) => {
  const buttonClasses = "dome-btn dome-btn-link"
  const buttonText = <span className="dome-p1 dome-color-green-gradient">{header}</span>

  return (
    <InstantQuoteLookupOverlayButton buttonText={buttonText} buttonClasses={buttonClasses} />
  )
}

const GetQuoteSimpleAd = ({ header = 'get insurance quote >', content = 'Match requirements instantly!' }) => {
  return (
    <div
      className="get-quote-simple-ad dome-rounded-border dome-light-shadow"
    >
      <div className="dome-d-flex dome-flex-column">
        <ClickableHeader header={header} />
        <div className='content-wrapper'>
          <Content content={content} />
          <Logos />
        </div>
      </div>
    </div>
  )
}

export default GetQuoteSimpleAd
