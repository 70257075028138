import React from 'react'
import PropTypes from 'prop-types'
import { FileItem } from './FileItem'

export function FileList({ files }) {
  return (
    <div className="dome-d-flex dome-flex-column dome-gap6">
      {Object.entries(files).map(([id, file]) => (
        <FileItem id={id} file={file} key={id} />
      ))}
    </div>
  )
}

FileList.propTypes = {
  files: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  }),
}
