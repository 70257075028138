import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import FileUploader from './FileUploader'
import { UploadProvider } from './uploadContext'
import { UPLOAD_STAGES } from 'components/utility/sandbox_upload/constants'

export default function SandboxUploadComponent(props) {
  const externalDispatch = useCallback((action) => {
    switch (action.type) {
      case 'ADD_FILE': {
        props.onFileUpload?.({
          uuid: action.payload.uuid,
          file: action.payload.file,
          progress: 0,
        })
        break
      }
      case 'UPDATE_PROGRESS': {
        props.onFileUpload?.({
          uuid: action.payload.uuid,
          progress: action.payload.progress,
        })
        break
      }
      case 'UPDATE_STATUS': {
        if (action.payload.status === UPLOAD_STAGES.COMPLETED) {
          props.onFileProcessed?.({
            uuid: action.payload.uuid,
          })
        }
        break
      }
      case 'SET_ERROR': {
        props.onFileError?.({
          uuid: action.payload.uuid,
          errors: {
            code: action.payload.code,
            message: action.payload.message,
            details: action.payload.details,
            retryable: action.payload.retryable,
          },
        })
        break
      }
      case 'REMOVE_FILE': {
        props.onFileRemoved?.({
          uuid: action.payload.uuid,
        })
      }
    }
  }, [])

  return (
    <UploadProvider externalDispatch={externalDispatch}>
      <FileUploader label={props.label} multiple={props.multiple} />
    </UploadProvider>
  )
}

SandboxUploadComponent.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onFileUpload: PropTypes.func,
  onFileProcessed: PropTypes.func,
  onFileError: PropTypes.func,
  onFileRemoved: PropTypes.func,
}
