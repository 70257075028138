import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { UPLOAD_STAGES } from './constants'

const UploadContext = createContext(null)

const initialState = {
  files: {}, // { [uuid: string]: FileUploadState }
  errors: {}, // { [uuid: string]: ErrorState }
}

const uploadReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FILE': {
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.uuid]: {
            file: action.payload.file,
            stage: UPLOAD_STAGES.UPLOADING,
            progress: 0,
            metadata: null,
            timestamp: Date.now(),
          },
        },
      }
    }

    case 'UPDATE_PROGRESS': {
      const file = state.files[action.payload.uuid]
      if (!file) return state
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.uuid]: { ...file, progress: action.payload.progress },
        },
      }
    }

    case 'UPDATE_STATUS': {
      const file = state.files[action.payload.uuid]
      if (!file) return state
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.uuid]: {
            ...file,
            stage: action.payload.stage,
            metadata: action.payload.metadata,
          },
        },
      }
    }

    case 'SET_ERROR': {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.uuid]: {
            code: action.payload.code,
            message: action.payload.message,
            details: action.payload.details,
            retryable: action.payload.retryable,
          },
        },
      }
    }

    case 'REMOVE_FILE': {
      const { [action.payload.uuid]: removedFile, ...remainingFiles } =
        state.files
      const { [action.payload.uuid]: removedError, ...remainingErrors } =
        state.errors
      return { ...state, files: remainingFiles, errors: remainingErrors }
    }

    default:
      return state
  }
}

export const UploadProvider = ({ children, externalDispatch }) => {
  const [state, dispatch] = useReducer(uploadReducer, initialState)

  const myOwnDispatch = useCallback((...args) => {
    dispatch(...args)
    externalDispatch?.(...args)
  }, [dispatch, externalDispatch])

  return (
    <UploadContext.Provider value={{ state, dispatch: myOwnDispatch }}>
      {children}
    </UploadContext.Provider>
  )
}

export const useUploadContext = () => {
  const context = useContext(UploadContext)
  if (!context) {
    throw new Error('useUploadContext must be used within an UploadProvider')
  }
  return context
}
