import React, { useRef } from 'react'
import PropTypes from 'prop-types'

export function Header({ children, multiple, onFilesAdded }) {
  const hiddenInputRef = useRef(null)
  const onClick = (e) => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click()
    }
  }

  const onAddFile = (e) => {
    if (e.target.files) {
      onFilesAdded(e.target.files)
      hiddenInputRef.current.value = ''
    }
  }

  return (
    <div
      className="file-upload-header dome-full-width dome-rounded-border"
      onClick={onClick}
    >
      <input
        type="file"
        ref={hiddenInputRef}
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={onAddFile}
      />
      {children}
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  multiple: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
}
