import axios from 'lib/axios'
import { parseError } from './errorUtils'

export const uploadApi = {
  generatePolicy: async (fileData) => {
    try {
      const response = await axios.post('/uploads/create_upload_url', {
        upload: {
          uuid: fileData.uuid,
          filename: fileData.name,
          content_type: fileData.type,
          content_md5: fileData.md5,
        },
      })
      return response.data
    } catch (error) {
      throw parseError(error)
    }
  },

  uploadFile: async (url, fields, file, md5, onProgress) => {
    const formData = new FormData()
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file)
    formData.append('Content-MD5', md5)

    try {
      await axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          onProgress(progress)
        },
      })
    } catch (error) {
      throw parseError(error)
    }
  },

  checkStatus: async (uuid) => {
    try {
      const response = await axios.get(`/uploads/${uuid}/status`)
      return response.data
    } catch (error) {
      if (error.response?.status === 404) {
        return { processing_status: 'pending' } // Return a default status instead of throwing
      }
      throw parseError(error)
    }
  },
}
